import React from "react"
import styled from "styled-components"
import { Link } from "gatsby";
import GlobalStyles from "../assets/styles/globalStyles";
import SEO from "../components/seo"
import Footer from "../components/footer";
import Navbar from "../components/navbar";

import SVGNieruchomosc from "../assets/images/nowu-biuro-nieruchomosci-icon-1.svg";

const PageStyles = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  max-width: 100%;

  @media (max-width: 992px) {
    height: 100%;
  }
`;

const MainStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: center;

  svg {
    margin-bottom: 24px;
    width: 500;
    height: 500;
  }

  p {
    color: #6f6f6f;
  }
  
  a {
    color: #8E2315;
    text-decoration: none;
  }
`;


const IndexPage = ({ data }) => (
  <PageStyles>
    <SEO />
    <GlobalStyles />
    <Navbar />
    <MainStyles>
      <SVGNieruchomosc />
      <h2>Ups... Nie posiadamy szukanego adresu :(</h2>
      <p>Zerknij na górny panel nawigacyjny, może znajdziesz tam to czego szukasz.</p>
      <Link to="/">Wróć do strony głównej</Link>
    </MainStyles>
    <Footer />
  </PageStyles>
)

export default IndexPage
